import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Message from './Message';
import axios from 'axios';

export const FileUpload = () => {
    const [file, setFile] = useState({});
    const [uploadedFile, setUploadedFile] = useState({});
    const [urlPath, setUrlPath] = useState('');
    const [message, setMessage] = useState('');
    const [uploadMessage, setUploadmessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("auth-token") === '') navigate('/admin/login');
    })

    const onChangeFile = e => {
        let fileObj = {};
        for (let i = 0; i < e.target.files.length; i++) {
            fileObj[i] = e.target.files[i];
        }
        setFile(fileObj);
    };

    const onSubmit = async e => {
        e.preventDefault();
        setUploadedFile({});
        setMessage(null);
        setUploadmessage('');
        const formData = new FormData();
        for (let key in file) {
            formData.append('file', file[key]);
        }
        const url = urlPath !== '' ?
            urlPath : '';
        formData.append('urlPath', url);

        try {
            setUploadmessage('El archivo se está subiendo, por favor no cierre ni actualice la página ...');
            const token = localStorage.getItem("auth-token");
            const res = await axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/api/${'upload'}`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-auth-token': token,
                    },
                });

            const { fileName, filePath } = res.data;

            setUploadedFile({ fileName, filePath });
            setMessage('Articulos creados en ARC correctamente');
            setUploadmessage(null);

        } catch (err) {
            if (err.response) {
                if (err.response.status === 500) {
                    if(err.response?.data?.file){
                        setMessage(`Conflicto en el servidor con el siguiente archivo: ${err.response?.data?.file}, por favor revise que esté bien construido o contacte al administrador`);
                    }else{
                        setMessage('Conflicto en el servidor, por favor contacte al administrador');
                    }
                    setUploadmessage(null);
                } else {
                    setMessage(err.response.data.msg);
                    setUploadmessage(null);
                }
            }
        }
    }

    return (
        <Fragment>
            {message ? <Message msg={message} /> : null}
            <form onSubmit={onSubmit}>
                <div className="mb-3">
                    <label className="form-label" id="addon-wrapping">Identificador del XML</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Identificador XML"
                        aria-label="Nombre de Usuario"
                        aria-describedby="addon-wrapping"
                        autoComplete="off"
                        maxLength="10"
                        onKeyPress={(e) => {
                            if (!/[a-zA-Z0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => { setUrlPath(e.target.value) }}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label" id="addon-wrapping">Carga del XML</label>
                    <input type="file" className="form-control" id="inputGroupFile02" accept=".xml" onChange={onChangeFile} multiple />
                </div>
                <button type="submit" className="btn btn-primary mt-4">Subir</button>
            </form>
            {
                uploadMessage && uploadMessage !== '' ?
                    <div className="alert alert-primary mt-4" role="alert">
                        {uploadMessage}
                    </div>
                    : ''
            }
            {
                uploadedFile &&
                <div className="row mt-5">
                    {
                        <div className="col-md-12 m-auto ">
                            <a href={uploadedFile.fileName} target="_blank" rel="noreferrer">
                                <h6 className="text-center" id="result-text">{uploadedFile.fileName}</h6>
                            </a>
                        </div>
                    }

                </div>
            }
        </Fragment>
    )
}
