import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Home from "./components/pages/Home";
import Report from "./components/pages/Report";
import Header from './components/layout/Header';
import UserContext from './context/UserContext';
import UpdateUser from "./components/pages/UpdateUser";

import "./App.css";
import Axios from 'axios';

function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
    role: undefined
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        let token = localStorage.getItem("auth-token");
        if (token === null) {
          localStorage.setItem("auth-token", "");
          token = "";
          if (window.location.pathname !== '/admin/login') {
            window.location.href = '/admin/login';
          }
        }
        const tokenRes = await Axios.post(
          `${process.env.REACT_APP_BACK_DOMAIN}/api/users/tokenIsValid`,
          null,
          { headers: { "x-auth-token": token } }
        );

        if (tokenRes.data) {
          const userRes = await Axios.get(`${process.env.REACT_APP_BACK_DOMAIN}/api/users`, {
            headers: { "x-auth-token": token },
          });
          setUserData({
            token,
            user: userRes.data,
            role: userRes.role,
          });
        } else {
          localStorage.setItem("auth-token", "");
          token = "";
          if (window.location.pathname !== '/admin/login') {
            window.location.href = '/admin/login';
          }
        }
      } catch (err) {
        console.error("Error al validar el token:", err);
        // Limpiar y redirigir si ocurre un error
        localStorage.setItem("auth-token", "");
        setUserData({ token: undefined, user: undefined, role: undefined });
        if (window.location.pathname !== '/admin/login') {
          window.location.href = '/admin/login';
        }
      }

    };

    checkLoggedIn();
  }, []);

  return (
    <>
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Header />
          <Routes>
            <Route path="/admin" exact element={<Home />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/report" element={<Report />} />
            <Route path="/admin/register" element={<Register />} />
            <Route path="/admin/update" element={<UpdateUser />} />
          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
